@import '../../../../Content/WebGiving/webgiving_theme/variables.less';

// FeatureFlagWillMakeThisCodeObsolete: NewFeatures.EmbeddedGiving_WebGiving
.container-old {
	background: black;
	width: 100%;
	height: 420px;
	text-align: center;
	position: absolute;
	z-index: @zindex-hero-image;
	overflow: hidden;

	@media @small {
		height: 260px;
	}
}
// end of Obsolete block

.container {
	background: black;
	width: 100%;
	height: 420px;
	text-align: center;
	position: absolute;
	z-index: @zindex-hero-image;
	overflow: hidden;

	@media @small {
		height: 300px;
	}
}

.image {
	display: inline-block;
	width: 1500px;
	max-width: 100%;
	height: 100%;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	background-color: #000;
}

.gradients {
	position: absolute;
	left: 50%;
	margin-left: -750px;
	z-index: 1;
	height: 100%;
	width: 1500px;

	&::before {
		content: ' ';
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-image: linear-gradient(
			90deg,
			rgba(0, 0, 0, 0.99) 0%,
			rgba(0, 0, 0, 0.6) 20%,
			rgba(0, 0, 0, 0.6) 80%,
			rgba(0, 0, 0, 0.99) 100%
		);
	}
}

.extended {
	@media @small {
		height: 350px;
	}
}
